/******************************************************************

768px and up

******************************************************************/

/*********************
GENERAL STYLES
*********************/

body {
	font-family: $font;
}

.day-check {
	float: none;
}

/*********************
LAYOUT & GRID STYLES
*********************/

.client-logo-container{
	margin: auto;
	width: 100%;
	text-align: center;
}

.sol-logo {
	width: 40%;
}
