/******************************************************************

Variables

******************************************************************/

$black:             #231f20;
$white:             #fff;
$yellow:			#FFFF00;
$light-yellow:		#fcfce6;

$light-gray:        #a3adb9;
$gray:              #eaedf2;
$text-color:        #5c6b80;

$link-color:        $black;
$link-hover:        darken($link-color, 9%);

$border-color:      #ccc;
$disabled-gray:     #cfcfcf;
$alert-red:			red;
$alert-green:		#645564;
$alert-blue:		blue;

$bold:				700;

$header-color:      #CCC;
$breadcrumb-color:  #EEE;
$footer-height:		55px;
$footer-color:      #999;
$spinner-color:     rgb(205, 164, 0);