/******************************************************************

Buttons

******************************************************************/

button {

	.toolbar & {
		min-width: 140px;
	}
	
}

.btn-primary,
.btn-success,
.btn-danger {
	
	& > i{
		font-size: 1.6em;
		margin-top: 5px;
	}
	
	&:visited,
	&:visited:link {
		color: $white;
	}

	&:hover,
	&:focus,
	&:visited:hover,
	&:visited:focus,
	&:hover:link,
	&:focus:link {
		color: $white;
	}
}

.btn {
	min-width: 55px;
}

.sol-add-btn,
.sol-del-btn {
	font-size: 1em;
	line-height: 1.8em;
	display: inline-flex;
	margin: 0 0.2em;

	i {
		margin: 0;
		font-size: 1.6em;
	}
}