/******************************************************************

481px and up

******************************************************************/

/*********************
POSTS & CONTENT STYLES
*********************/

.client-logo-container{
	margin: auto;
	width: auto;
    text-align: center;
    
    img {
		height: 36px;
	}
}

.sol-edit {
    font-size: 1.2em;
	line-height: 1.5em;
}

.sol-logo {
	width: 60%;
}