/******************************************************************

1030px and up

******************************************************************/

body {
	font-size: 1.4em;
}

.sol-info {
	text-align: right;
}

a.nav-link,
a.nav-link:visited {
	line-height: 1.4em;
	font-size: 0.9em;
}

.sol-edit {
    font-size: 1.4em;
	line-height: 1.4em;
}

.sol-logo {
	width: 30%;
}
