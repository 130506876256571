/******************************************************************

Main

******************************************************************/

html {
	background-color: $footer-color;
}

.main-container {
	margin-top: 60px; /* space for the header */
	background-color: $white;
	min-width: 320px;
}

header {
	background-color: $header-color;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;
	min-width: 320px;
}

.bg-gray {
	background-color: $header-color;
}

.breadcrumb {
	background-color: $breadcrumb-color;
}

.navbar {
	margin-bottom: 0;

	a {
		cursor: pointer;
	}
}
.navbar-brand {
	padding: 3px 0;
}
.main-logo {
	height: 36px;
	margin: 0;
}

.card-header h2 {
	margin: 0;
}

.sol-week {
	color: $text-color;
}

.sol-day-item {
	padding: 16px 10px 0;
	border-bottom: 1px solid #DDD; 
}

.sol-desc {
	font-size: 0.8em;
	color: $light-gray;
}

.sol-disclaimers {
	font-size: 0.7em;
	margin-bottom: 1em;
}

.sol-muted {
	color: $light-gray;
	font-size: 0.8em;
}

.sol-list-group {
	.list-group-item:disabled {
		background-color: rgba(156, 159, 163, 0.1);
		color: grey;
		font-style: italic;
	}
	.list-group-item.active {
		background-color: rgba(189, 217, 247, 0.502);
		color: #007bff;
	}
}

.booking-list-item {
	.booking-name {
		width: 25%;
	}
	.booking-status {
		width: 15%;
	}
	.booking-stop {
		font-size: 0.7em;
	}
	.booking-time {
		width: 7%;
	}
	.booking-check {
		width: 3%;
		padding-top: 8px;
	}
}

a.nav-link,
a.nav-link:visited {
	line-height: 1.4em;
	font-size: 1.2em;

	&:hover,
	&:focus,
	&.active {
		color: #31708f;
	}
}

.sol-flex {
	display: flex;
}

#map-container {
	height: 200px;
}

footer {
	background-color: $footer-color;

	p {
		text-align: center;
		padding: 8px;
		margin: 0;
		
		a {
			color: #EEE;
			margin: auto;
			&:hover,
			&:focus,
			&:visited {
				color: $white;
			}
		}
	}
}