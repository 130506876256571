.ngdialog.ngdialog-theme-default.dialog {
  padding: 5% 0;

  .ngdialog-close:before {
    top: 8px;
    z-index: 1001;
  }

  .ngdialog-content {
    z-index: 1001; /* fix y scroll */
    border-radius: 0;
    padding: 2em;
    background: #FFF;
    border-radius: 5px;

    fieldset legend {
      margin: 15px;
    }

    header {
      font-size: 1.5em;
      line-height: 0.9em;
      text-transform: uppercase;
      color: #FFF;
      min-height: 1em;
      height: 3em;
      z-index: 1001;
      padding-top: 1px;

      p {
        margin: 0.8em 0 0.6em;
        text-align: left;
      }
    }

    .content {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;

      .scroll-content {
        position: relative;
        z-index: 1000;
        background: #fff;
        width: 100%;
        max-height: 60em;
        overflow-x: hidden;
        overflow-y: auto; /*auto*/
        border-radius: 0;

        .scroll-content__footer {
          padding-bottom: 1em;
        }

        label {
          font-size: 1.3em;
          font-weight: 800;
          text-transform: uppercase;
        }
      }
    }

    h3 {
        text-transform: uppercase;
        font-size: 1.2em;
        margin: 1em 0.5em;
    }

  }

}

.dialog_login {
  .alert {
    font-size: 1em;
    .close{
      visibility: hidden;
      display: none;
    }
  }
}
